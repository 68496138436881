
import React from "react";
import {Avatar, Image} from "antd";
import axios from "axios";

function AvatarProductos(props){

    let { 
        imagenes = [] ,
        shape="square",
        size=64,
    } = props;


    let src = null;

    if(imagenes.length > 0){
        if(imagenes[0].filename) src = axios.defaults.baseURL + '/upload/' + imagenes[0].filename
        if(imagenes[0].url) src = imagenes[0].url
    }

    return <Avatar
        shape={shape}
        size={size}
        src={src}
    />

}

function ImageProductos(props){

    let { 
        imagenes = [] ,
        width=64,
        height=64
    } = props;


    let src = null;

    if(imagenes.length > 0){
        if(imagenes[0].filename) src = axios.defaults.baseURL + '/upload/' + imagenes[0].filename
        if(imagenes[0].url) src = imagenes[0].url
    }

    return <Image
        width={width}
        height={height}
        src={src}
    />

}


export default AvatarProductos;

export {
    AvatarProductos,
    ImageProductos
}
